.tile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 150px;
	height: 150px;
	border-radius: 2px;
	background-color: #FFF;
	margin-right: 20px;
	margin-bottom: 20px;
	transition: all 0.1s;
}

.tile:hover {
	width: 158px;
	height: 158px;
	margin-left: -4px;
	margin-right: 16px;
	margin-top: -8px;
}

.tile-image {
	width: calc(100% - 2px)
}

.banner {
	position: absolute;
	display: flex;
	align-items: center;
	padding-left: 10px;
	border-radius: 5px;
	height: 20px;
	width: 100%;
	bottom: 10px;
	background-color: #F3E9D2;
	border-top: 1px solid #ccc;
	color: #282C34;
	font-size: 11px;
}

.banner-bubble {
	z-index: 1;
	display: flex;
	position: absolute;
	margin: auto;
	bottom: 8px;
	border-radius: 2px;
	font-weight: 600;
	right: 2px;
	background-color: #C6DABF;
	border: 1px solid #F3E9D2;
	width: 30px;
	height: 24px;
	font-size: 10px;
	align-items: center;
	justify-content: center;
	color: #fff;
}

@media only screen and (max-width: 600px) {
	.tile {
		margin-right: 0px;
		margin-bottom: 20px;
	}
}
