.fade-in-button1 {
  animation-name: fadeIn1;
  animation-duration: 0.5s;
}

@keyframes fadeIn1 {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.fade-in-button2 {
  animation-name: fadeIn2;
  animation-duration: 1s;
}

@keyframes fadeIn2 {
   0% {
      opacity: 0;
   }
   50% {
   		opacity: 0;
   }
   100% {
      opacity: 1;
   }
}   

.fade-in-button3 {
  animation-name: fadeIn3;
  animation-duration: 1.5s;
}

@keyframes fadeIn3 {
   0% {
      opacity: 0;
   }
   70% {
   	opacity: 0;
   }
   100% {
      opacity: 1;
   }
}      

.fade-in {
  width: 100%;
  animation-name: slideInDown;
  animation-duration: 1s;
}

@keyframes slideInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}    