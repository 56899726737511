.App {
  background-color: #282c34;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

a {
  color: #F3E9D2;
  font-weight: 700;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.body {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100vw;
  padding: 100px;
}

.profypic {
  border-radius: 100%;
  border: 1px solid #F3E9D2;
  height: 150px;
  margin-right: 50px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  margin-right: 50px;
}

button {
  background-color: #C6DABF;
  border: 0px solid #C6DABF;
  width: 100px;
  height: 30px;
  color: #282C34;
  border-radius: 2px;
  margin-right: 10px;
  transition: all 0.5s;
  text-transform: lowercase;
  margin-bottom: 10px;
}

button:hover {
  background-color: #88D498;
}

.nav-button {
  background-color: #F3E9D2;
}

.nav-button:hover {
  background-color: #fff8e8;
  border-left: 10px solid #C6DABF;
  border-right: 0px solid #fff8e8;
}

.nav-button:active {
  background-color: #C6DABF;
  border-left: 5px solid #C6DABF;
  border-right: 5px solid #C6DABF;
}

.content {
  display: flex;
  flex: 1;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.portfolio-row {
  display: flex;
  flex-wrap: wrap;
}

.project-image {
  margin-right: 20px;
  margin-bottom: 20px;
  height: 300px;
  border: 1px solid #F3E9D2;
}

.github-logo {
  margin-right: 5px;
  width: 25px;
}

.employment-logo {
  position: relative;
  top: 6px;
  max-width: 32px;
  max-height: 32px;
  margin-right: 10px;
}

.employment-header {
  margin-bottom: 0px;
}

.employment-previous {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.employment-job {
  margin-right: 10px;
}

.employment-subheader {
  position: relative;
  font-weight: 400;
  color: #F3E9D2;
  margin-top: 10px;
}

.video-container {
  position: relative;
  padding-top: 56.25%;
  margin-bottom: 20px;
  border: 1px solid #C6DABF;
  border-radius: 2px;
}

.video {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

.social-row {
  height: 20px;
  width: 47px;
  background-color: #F3E9D2;
  border-radius: 2px;
  border: 1px solid #C6DABF;
  margin-top: -15px;
  margin-left: 55px;
}

.email {
  font-size: 13px;
}

.github-small {
  margin-right: 6px;
}

@media only screen and (max-width: 800px) {
  .button-group {
    margin-right: 0px;
    justify-content: space-between;
  }
  .button-group button {
    margin-right: 0px;
  }

  .profypic {
    margin-right: 0px;
  }

  .action-group {
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .body {
    padding: 30px;
    justify-content: center;
  }

  .content {
    padding-left: 0px;
    padding-right: 0px;
  }

  .employment-previous {
    display: block;
  }

  .portfolio-row {
    justify-content: space-between;
  }
}

.canada {
  position: relative;
  top: 5px;
  left: 5px;
  width: 32px;
}